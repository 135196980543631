import React from "react";
import { ConfigProvider } from "antd";
import { Navigate, useRoutes } from "react-router-dom";
import { lightTheme } from "./themes/theme";
import "./App.scss";

const UploadPage = React.lazy(() => import("./Pages/UploadPage"));
const ReviewPage = React.lazy(() => import("./Pages/ReviewPage"));

function App() {
  const routes = useRoutes([
    { path: "/", element: <Navigate replace to="/review" /> },
    {
      path: `/upload`,
      element: <UploadPage />,
    },
    {
      path: `/review`,
      element: <ReviewPage />,
    },
    {
      path: `/review/:documentId`,
      element: <ReviewPage />,
    },
  ]);
  return <ConfigProvider theme={lightTheme}>{routes}</ConfigProvider>;
}

export default App;
