import { configureStore } from "@reduxjs/toolkit";
import documentSlice from "./documentSlice";

export const store = configureStore({
  reducer: {
    documentSlice,
  },
});

export type RootState = ReturnType<typeof store.getState>;

// Infer the `RootState` and `AppDispatch` types from the store itself
export type AppDispatch = typeof store.dispatch;
