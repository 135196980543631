export const navigationItems = [
  {
    label: "Upload Documents",
    url: "/upload",
    className: "",
  },
  {
    label: "Review Documents",
    url: "/review",
    className: "",
    activeIcon: "",
  },
];

const ANNOTATORS_LIST = [
  "AbdelRahman ElSehaily",
  "Abdelrahman Hosny",
  "Ahmed Sherif",
  "Alan Hogue",
  "Ayman Madkour",
  "Belal Gouda",
  "Fatma Akram",
  "Fadia Tarek",
  "Islam Mohamed",
  "Khaled Abdelhamid",
  "Michael Raef",
  "Mohamed Magdy",
  "Mohamed Rashad",
  "Muhammad Nashaat",
  "Randa Moustafa",
  "Sherif Sabry",
];
