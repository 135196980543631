import { ThemeConfig } from "antd/es/config-provider/context";

const colorPalette = {
  black: {
    100: "#f5f5f5",
    150: "#BEBEBE",
    200: "#e9e9e9",
    250: "#c6c6c6",
    300: "#8B8B8B",
    400: "#A2A4BF",
    450: "#424D5C",
    500: "#252C35",
    600: "#000000",
  },
  white: {
    100: "#dee8ef",
    300: "#DDE8F2",
    600: "#FFFFFF",
  },
  blue: {
    100: "#E8F1F9",
    200: "#F1F6FA",
    225: "#BACCDC",
    250: "#1d5caa80",
    300: "#8FAED4",
    400: "#236a72",
    500: "#1D5CAA",
    600: "#1C6ACA",
    650: "#068ced",
    700: "#1B355C",
    800: "#25294f",
  },
  red: {
    100: "#d7383833",
    200: "#F9ECEC",
    500: "#D73838",
  },
  green: {
    100: "#4fac6333",
    200: "#e7ffec",
    300: "#1D99AA",
    500: "#4FAC63",
  },
  orange: {
    100: "#ffcc7a33",
    200: "#e0764833",
    250: "#f1a4021a",
    300: "#C5A26D",
    400: "#F1A402",
    500: "#EF9E1C",
    600: "#E07648",
    700: "#816157",
  },
  yellow: {
    100: "#FFF6E2",
    600: "#ffff00",
  },
};
const lightPalette = {
  header: {
    "header-border": colorPalette.black[400],
    "navigation-border": colorPalette.blue[650],
    "nav-item": colorPalette.black[450],
    "nav-item-active": colorPalette.black[500],
  },
  review: {
    bg: colorPalette.blue[200],
  },
};

export const lightTheme: ThemeConfig = {
  token: {
    borderRadius: 0,
  },
  components: {
    Table: {
      colorBorderSecondary: colorPalette.white[100],
    },
    Input: {
      colorBgContainer: colorPalette.blue[200],
      colorBorder: colorPalette.blue[100],
      colorBgContainerDisabled: colorPalette.blue[200],
    },
    Button: {
      colorPrimary: colorPalette.blue[500],
      colorPrimaryHover: colorPalette.blue[600],
      colorBgContainerDisabled: colorPalette.blue[250],
      colorTextDisabled: colorPalette.white[600],
      colorBorder: "",
    },
    Alert: {
      colorWarningBg: colorPalette.orange[250],
    },
    Checkbox: {
      colorPrimary: colorPalette.blue[500],
      colorBorder: colorPalette.black[400],
      lineWidth: 1.5,
      borderRadiusSM: 4,
    },
  },
};
const createCssVar = (items: any, prefix = "-"): string[] =>
  Object.entries(items).flatMap(([key, value]) => {
    const varName = `${prefix}-${key}`;
    if (typeof value === "object") return createCssVar(value, varName);
    return `${varName}:${value}`;
  });

const createCssVars = (themeColors: any) => createCssVar(themeColors).join(";");

const sheet = document.styleSheets[0];
sheet.insertRule(`:root{${createCssVars(lightPalette)}}`);

export {};
