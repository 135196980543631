import { Layout } from "antd";
import { NavLink, useLocation } from "react-router-dom";
import classNames from "classnames";
import "./Header.scss";
import { navigationItems } from "../utils/constants";

const HeaderComponent = () => {
  const { Header } = Layout;
  const location = useLocation();

  const isActiveNavItem = (url: string) => location.pathname.match(`${url}/*`);

  const getNavLinkClasses = (url: string) =>
    classNames({
      "nav-item": true,
      active: isActiveNavItem(url),
    });

  const handleLinkClick = (e: any, className: string) => {
    if (className === "greyed-out") {
      e.preventDefault();
    }
  };
  return (
    <Header className="app-header">
      <div className="header-container">
        <div className="header-title">
          <img className="logo" src="/assets/LogoFull.svg" alt="agolo-logo" />
        </div>
        <ul className="navigation-menu">
          {navigationItems.map((item: any) => {
            if (!item.hide) {
              return (
                <NavLink
                  to={`${item.url}`}
                  key={`${item.label}-entity-link`}
                  className={item.className}
                  onClick={(e) => {
                    handleLinkClick(e, item.className);
                  }}
                >
                  <li
                    key={`${item.label}-entity-item`}
                    className={getNavLinkClasses(item.url)}
                  >
                    {item.label}
                  </li>
                </NavLink>
              );
            }
            return <></>;
          })}
        </ul>
      </div>
    </Header>
  );
};

export default HeaderComponent;
