import { createSlice } from "@reduxjs/toolkit";

interface documentState {}

const initialState = {} as documentState;

export const documentSlice = createSlice({
  name: "document",
  initialState,
  reducers: {},
  extraReducers() {},
});

export default documentSlice.reducer;
